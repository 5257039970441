<template>
  <div class="wrapper" :class="stickyPeriods?'slim':''">
    <div class="title-container">
      <h3>REGERINGSPERIODER:</h3>
    </div>
    <div class="all-container">
      <div class="all-button" @click="selectedPeriod='allPeriods'" :class="selectedPeriod === 'allPeriods'?'clicked':''">ALLE<span class="hide-on-mobile">&nbsp;PERIODER</span></div>
    </div>

    <div class="periods-container">
      <div class="period-buttons-container">
        <template v-for="(gov, index) in transformedPeriodsData">
          <div class="regerings-periode btn-right" :key="index" :style="`width: ${gov.time/gov.totalTime*100}%`" @click="(gov.periodID === 'period1' || gov.periodID === 'period2') && topicName === 'ALT' && view === 'party'?'':selectedPeriod = gov.periodID" :class="[(gov.periodID === 'period1' || gov.periodID === 'period2') && topicName === 'ALT' && view === 'party'?'alt-remove':'', selectedPeriod === gov.periodID?'clicked':'', selectedPeriod === 'allPeriods'?'btn-outline':'']">
            <template v-for="(party, index) in gov.govParties">
              <div class="party-logo" :key="index">
                <PartyLogo :party="party" :size="'small'" :circle="true" :tooltip="false" />
              </div>
              <!-- <img :src="partyLogo(party)" alt=""> -->
            </template>
            <p class="date" :class="stickyPeriods?'hide':''">{{gov.startDate.slice(0,4)}}</p>
            <p v-if="index === transformedPeriodsData.length-1" class="date last-date" :class="stickyPeriods?'hide':''">{{(new Date()).getFullYear()}}</p>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.wrapper {
  width: 92%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  &.slim {
    height: 40px;
    .period-buttons-container {
      height: 75%;
    }
    .all-button {
      height: 75%;
    }
  }
}
.title-container,
.periods-container,
.all-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70%;
  &:hover {
    .date {
      opacity: 1;
    }
  }
}
.title-container {
  align-items: flex-start;
  width: 18%;
  h3 {
    margin: 0;
    font-family: "futura-pt-condensed", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }
}
.periods-container {
  width: 70%;
}
@media (max-width: $bp-narrow) {
  .wrapper {
    width: 100%;
  }
  .title-container {
    display: none;
  }
  .periods-container {
    width: 88%;
  }
  .hide-on-mobile {
    display: none;
  }
  .date {
    font-size: 7px;
  }
}

.all-container {
  width: 18%;
  // background: purple;
  display: flex;
  justify-content: center;
  align-items: center;
  .all-button {
    width: 70%;
    color: white;
  }
}
.period-buttons-container {
  position: relative;
  width: 100%;
  height: 70%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .alt-remove {
    cursor: auto;
    background: #e5e5e5;
    &:hover {
      background: #e5e5e5;
    }
  }
}
.regerings-periode {
  position: relative;
  cursor: pointer;
  height: 100%;
  background: $color-grey;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  .party-logo {
    margin: 0 2%;
    height: 15px;
    width: 15px;
    z-index: 99999;
    filter: grayscale(50%);
  }
  p {
    transition: 0.3s;
    opacity: 1;
    font-family: "futura-pt";
    font-weight: 500;
    font-size: 12px;
    margin: 0;
  }
  .hide {
    opacity: 0;
  }
  .date {
    position: absolute;
    font-family: "futura-pt";
    font-weight: 700;
    top: 105%;
    left: 0;
    padding: 1px;
    transform: translateX(-55%);
    &.last-date {
      left: auto;
      right: 0;
      transform: translateX(0);
    }
  }
}
.slim {
  .date {
    top: 94%;
    font-size: 8px;
  }
}
@media (max-width: $bp-narrow) {
  .periods-container {
    img {
      height: 35%;
      width: auto;
    }
    p {
      font-size: 50%;
    }
    .slim {
      display: none;
    }
    &:hover {
      .date {
        display: none;
      }
    }
  }
}

.all-button {
  font-family: "futura-pt-condensed";
  font-weight: 700;
  font-size: 16px;
  height: 70%;
  cursor: pointer;
  background: $color-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    background: $color-red;
  }
  &.clicked {
    background: $color-red;
  }
}

/* The button-maker */
@mixin arrow-button($color, $background, $shadow) {
  background: none;
  border: 0;
  position: relative;
  height: 100%;

  background: $background;
  &:hover {
    background: $color-red;
  }

  &.clicked {
    background: $color-red;
  }
}
.btn-outline {
  &:before {
    opacity: 1;
  }
}

/* How to include it */
.btn-right {
  @include arrow-button(#fff, $color-grey, $color-grey);
}
</style>

<script>
import PartyLogo from "@/components/PartyLogo";
import parties from "@/assets/parties/parties.json";
import axios from "axios";
export default {
  props: ["stickyPeriods", "topicName", "view"],
  components: {
    PartyLogo
  },
  data() {
    return {
      selectedPeriod: "allPeriods",
      periodsData: null
    };
  },
  methods: {
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    }
  },
  computed: {
    transformedPeriodsData: function() {
      if (this.periodsData) {
        let data = JSON.parse(JSON.stringify(this.periodsData));
        let oneDay = 24 * 60 * 60 * 1000;
        let today = new Date().getTime();
        let startDate = new Date(data[0].startDate).getTime();
        let totalTime = today - startDate;
        return data.map(x => {
          if (x.endDate) {
            x.time =
              new Date(x.endDate).getTime() - new Date(x.startDate).getTime();
          } else {
            x.time = today - new Date(x.startDate).getTime();
          }
          x.totalTime = totalTime;
          return x;
        });
      } else {
        return "";
      }
    },
    sortedPeriods: function() {
      return this.periods
        .map((x, index) => {
          x.index = index;
          return x;
        })
        .filter(x => x.periodID !== "all")
        .sort((a, b) => a.periodID - b.periodID);
    },
    allPeriods: function() {
      return this.periods.filter(x => x.periodID === "all");
    }
  },
  watch: {
    selectedPeriod: function() {
      this.$eventBus.$emit("selectedPeriod", this.selectedPeriod);
    }
  },
  created() {
    this.$eventBus.$emit("selectedPeriod", this.selectedPeriod);
  },
  mounted() {
    let self = this;
    axios
      .get("/static/periods.json")
      .then(response => (self.periodsData = response.data));
    this.$eventBus.$emit("selectedPeriod", this.selectedPeriod);
  }
};
</script>
