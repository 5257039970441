<template>
  <div class="multi-page-box-container" :style="`background: ${background}`">
    <div class="inner-container">
      <transition name="fade" mode="out-in">
        <template v-for="page in pages">
          <div class="slot-wrapper" :key="page" v-if="selectedPage === page">
            <slot :name="`page-${page}`"></slot>
          </div>
        </template>
      </transition>
    </div>
    <div class="circles-container">
      <template v-for="page in pages" v-if="pages>1">
        <div class="circle" :class="selectedPage===page?'circle-active':''" @click="selectedPage = page" :key="page"></div>
      </template>
    </div>
    <div class="arrow arrow-left" v-if="selectedPage>1" @click="selectedPage--">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 477.175 477.175" style="enable-background:new 0 0 477.175 477.175;" xml:space="preserve" width="512px" height="512px">
        <g>
          <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" :fill="arrows" />
        </g>
      </svg>
    </div>
    <div class="arrow arrow-right" v-if="selectedPage<pages" @click="[selectedPage++, bounceClicked=true]" :class="animate&&!bounceClicked?'bounce-right':''">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 477.175 477.175" style="enable-background:new 0 0 477.175 477.175;" xml:space="preserve" width="512px" height="512px">
        <g>
          <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5   c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z" :fill="arrows" />
        </g>
      </svg>
    </div>
  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.multi-page-box-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.inner-container {
  position: relative;
  width: 88%;
  height: 88%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circles-container {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  .circle {
    cursor: pointer;
    width: 6px;
    height: 6px;
    margin: 1px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid grey;
    border-radius: 10px;
    margin-right: 3px;
    &.circle-active {
      background: grey;
    }
  }
}
.arrow {
  position: absolute;
  top: 50%;
  width: 5%;
  height: 5%;
  // transform: translateY(-50%);
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  &.arrow-left {
    left: 2%;
  }
  &.arrow-right {
    right: 2%;
  }
}

@keyframes bounceDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(15px);
  }
}
.bounce-left {
  animation: bounceLeft 2s infinite;
}
.bounce-right {
  animation: bounceRight 2s infinite;
}
</style>

<script>
export default {
  props: {
    background: {
      default: "#FFF"
    },
    pages: {
      default: 5
    },
    selectPage: {
      default: 1
    },
    arrows: {
      default: "#000"
    },
    circles: {
      default: "grey"
    },
    animate: {
      default: false
    }
  },
  data() {
    return {
      selectedPage: 1,
      bounceClicked: false
    };
  },
  computed: {
    circleStyle: function() {
      return `border: 1px solid ${this.circles};`;
    }
  },
  watch: {
    selectedPage: function() {
      this.$emit("selectedPage", this.selectedPage);
    },
    selectPage: function() {
      if (this.selectPage !== null) {
        this.selectedPage = this.selectPage;
      } else {
        this.selectedPage = 1;
      }
    }
  }
};
</script>
