<template>
  <div class="overlay-container" :style="!showPoliticianInfo?'':'opacity: 1; z-index: 100'">
    <div class="outer-container" v-if="speaker">
      <div class="inner-container">
        <div class="close-btn" @click="$emit('closePolitician')">
          <img src="@/assets/icons/cancel.svg" alt="">
        </div>
        <div class="photo-container">
          <img :src="getStaticPoliticianImg(speaker)" alt="">
        </div>
        <div class="title-container" v-if="speaker && parsedBio">
          <div class="name">
            <p v-if="speaker">{{speaker.politician.toUpperCase()}}</p>
            <p v-if="speaker" class="job">{{parsedBio.profession}}</p>
          </div>
          <div class="party">
            <img :src="partyLogo(speaker.partyID)" alt="">
            <p v-if="speaker">{{parties[speaker.partyID].name.toUpperCase()}}</p>
          </div>
        </div>
        <div v-if="parsedBio" class="bio-container">
          <!-- <p>{{parsedBio.profession}}</p> -->
          <p>{{parsedBio.memberData}}</p>

          <b>Uddannelse og erhverv</b>
          <ul v-if="parsedBio.educations && parsedBio.occupations">
            <template v-if="parsedBio.educations.education && typeof(parsedBio.educations.education) !== 'string'" v-for="(education, index) in parsedBio.educations.education">
              <li :key="'educationoccupation' + index">{{education}}</li>
            </template>
            <template v-if="typeof(parsedBio.educations.education) == 'string'">
              <li>{{parsedBio.educations.education}}</li>
            </template>

            <template v-if="parsedBio.occupations.occupation && typeof(parsedBio.occupations.occupation) !== 'string'" v-for="(occupation, index) in parsedBio.occupations.occupation">
              <li :key="'occupation' + index">{{occupation}}</li>
            </template>
            <template v-if="typeof(parsedBio.occupations.occupation) == 'string'">
              <li>{{parsedBio.occupations.occupation}}</li>
            </template>
          </ul>

          <ul v-else-if="parsedBio.educations">
            <template v-if="parsedBio.educations.education && typeof(parsedBio.educations.education) !== 'string'" v-for="(education, index) in parsedBio.educations.education">
              <li :key="'education' + index">{{education}}</li>
            </template>
            <template v-if="typeof(parsedBio.educations.education) == 'string'">
              <li>{{parsedBio.educations.education}}</li>
            </template>
          </ul>

          <ul v-else-if="parsedBio.occupations">
            <template v-if="parsedBio.occupations.occupation && typeof(parsedBio.occupations.occupation) !== 'string'" v-for="(occupation, index) in parsedBio.occupations.occupation">
              <li :key="'occupation' + index">{{occupation}}</li>
            </template>
            <template v-if="typeof(parsedBio.occupations.occupation) == 'string'">
              <li>{{parsedBio.occupations.occupation}}</li>
            </template>
          </ul>

        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s;
  z-index: -10;
}

.outer-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: $color-lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 6%;
  height: 6%;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
  }
}
.inner-container {
  height: 90%;
  width: 90%;
  position: relative;
  padding: 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.photo-container {
  width: 35%;
  height: auto;
  // background: rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    height: auto;
    filter: saturate(0.6);
  }
}
.title-container {
  width: 58%;
  height: 35%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border: 1px dashed grey;
  .name,
  .party {
    width: 100%;
    font-size: 150%;
    p {
      font-family: "futura-pt";
      font-weight: 700;
      width: 100%;
      margin: 0;
    }
  }
  .name {
    // Need to add space between the name and party element when names are long.
    margin-bottom: 5%;
    height: 30%;
    p.job {
      font-size: 70% !important;
      color: $color-grey;
      font-weight: 700;
    }
  }
  .party {
    align-self: flex-start;
    height: 20%;
    font-size: 120%;
    cursor: pointer;
    color: $color-darkgrey;
    display: flex;
    align-items: center;
    img {
      height: 75%;
      margin-right: 3%;
    }
  }
}
.bio-container {
  width: 100%;
  height: 60%;
  text-align: left;
  p,
  ul,
  li,
  b {
    text-align: justify;
    font-size: 100%;
  }
}
// .overlay-container {
//   position: absolute;
//   width: 100%;
//   height: 98%;
//   top: 2%;
//   left: 0;
//   opacity: 0;
//   transition: 0.3s;
//   z-index: -10;
//   box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.2);
// }
</style>

<script>
import globalConfig from "@/assets/globalConfig.json";
import parties from "@/assets/parties/parties.json";
import axios from "axios";
import xml from "pixl-xml";

export default {
  props: ["speaker", "showPoliticianInfo"],
  data() {
    return {
      globalConfig,
      parties,
      parsedBio: null
    };
  },
  methods: {
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    getStaticPoliticianImg: function(speaker) {
      let url =
        globalConfig.staticAssetsCDN +
        "/images/politicianID_" +
        speaker.politicianID +
        "_size_240.jpg";

      return url;
    }
  },
  watch: {
    speaker: function() {
      let self = this;
      if (this.speaker !== null) {
        axios
          .get(
            globalConfig.apiBaseUrl +
              "/speaker/speaker/" +
              self.speaker.politicianID
          )
          .then(function(response) {
            if (response.data.response[0].bio !== null) {
              var xml_string =
                '<?xml version="1.0" encoding="UTF-8"?>' +
                response.data.response[0].bio;
              self.parsedBio = xml.parse(xml_string);
            } else {
              self.parsedBio = null;
            }
            // if(self.speaker.politicianPhoto === "") {
            //   self.speaker.politicianPhoto = self.parsedBio.pictureMiRes;
            // }
          });
      } else {
        return;
      }
    }
  }
};
</script>
