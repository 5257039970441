<template>
  <div class="wrapper">

    <MultiPageBox :pages="totalPages" :selectPage="selectedPage" @selectedPage="setSelectedPage" :animate="animate">
      <template v-for="(page, index) in totalPages" :slot="`page-${page}`">

        <transition name="fade" mode="out-in" :key="index">
          <template v-for="period in periods">
            <div class="speakers" :key="period" v-if="period === selectedPeriod">

              <!-- PLaceholder tile with ALT-Logo for period1 & period2 in Section Speakers -->
              <div class="speaker" v-if="!partyView && speakers[0].length === 8 ">
                <!-- <div class="speaker-background"></div> -->
                <div class="speaker-image-disabled">
                  <img :src="partyLogo('ALT')" alt="">
                </div>
              </div>

              <template v-for="(speaker, index) in speakers[page - 1]">
                <div :key="speaker.politicianId" class="speaker" :class="`${speakerHighlight(speaker)}`" @mouseenter="showInfoCard(index+1)" @mouseleave="hideInfoCard()">
                  <!-- <div class="speaker-background"></div> -->
                  <div class="speaker-image" @click="selectPolitician(speaker)">
                    <img :src="getStaticPoliticianImg(speaker)" alt="" w="100%">
                  </div>
                  <div class="speaker-party-logo">
                    <PartyLogo :party="speaker.partyID" :size="'small'" :circle="true" />
                  </div>
                  <div class="speaker-name-card">
                    <p @click="selectPolitician(speaker)">{{speaker.politician.toUpperCase()}}</p>
                  </div>
                </div>
              </template>

              <!-- Auto-filling empty tiles for less than 9 tiles in partyView -->
              <template v-for="(emptyTile, index) in emptyTiles" v-if="partyView">
                <div class="speaker speaker-missing" :key="index"></div>
              </template>

            </div>
          </template>
        </transition>

      </template>
    </MultiPageBox>

    <PoliticianInfo :speaker="selectedSpeaker" :showPoliticianInfo="showPoliticianInfo" @closePolitician="showPoliticianInfo = false; selectedSpeaker = null" />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.wrapper {
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.speakers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  .speaker {
    display: inline-block;
    position: relative;
    width: 26%;
    height: 26%;
    margin: 3%;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s;
    perspective: 1000px;
    &.speaker-highlighted::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 10px solid $color-lightgreen;
      z-index: 9;
      pointer-events: none;
    }
    &.speaker-hidden {
      opacity: 0.4;
    }
    .speaker-name-card {
      position: absolute;
      bottom: -18%;
      right: -5%;
      background: $color-lightgrey;
      width: 110%;
      height: 27%;
      opacity: 0;
      transition: 0.3s;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-family: "futura-pt";
        font-weight: 700;
        margin: 0;
        color: black;
      }
      .speaker-party-name {
        color: $color-darkgrey;
      }
    }
    &:hover {
      .speaker-name-card {
        bottom: -14%;
        opacity: 1;
      }
    }
    .party-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%;
      z-index: 99;
    }
    .speaker-image,
    .speaker-image-disabled,
    .speaker-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .speaker-image {
      z-index: 2;
      background: $color-lightgrey;
      img {
        display: block;
        width: 100%;
        filter: grayscale(80%);
        &:hover {
          filter: grayscale(50%);
        }
      }
    }
    .speaker-image-disabled {
      z-index: 2;
      background: $color-lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        opacity: 0.2;
      }
    }
    .speaker-background {
      background: $color-grey;
      top: 4%;
      left: 4%;
      z-index: 1;
    }
    .speaker-party-logo {
      position: absolute;
      top: -5%;
      right: -5%;
      width: 20%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      cursor: initial;
    }
  }
}
.show-card {
  opacity: 1;
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import globalConfig from "@/assets/globalConfig.json";
import MultiPageBox from "@/components/MultiPageBox";
import PoliticianInfo from "@/components/PoliticianInfo";
import PartyLogo from "@/components/PartyLogo";
export default {
  props: {
    animate: {
      default: false
    },
    sectionData: {},
    partyView: {
      default: false
    },
    speakerWordsMode: {
      default: false
    },
    partyID: {}
  },
  components: {
    MultiPageBox,
    PoliticianInfo,
    PartyLogo
  },
  data() {
    return {
      globalConfig,
      parties,
      selectedPeriod: "allPeriods",
      visibleCard: null,
      selectedSpeaker: null,
      selectedParty: null,
      showPoliticianInfo: false,
      selectedPage: 1
    };
  },
  methods: {
    speakerHighlight: function(speaker) {
      if (this.selectedSpeaker) {
        if (
          this.selectedSpeaker.politicianID === speaker.politicianID &&
          this.speakerWordsMode
        ) {
          return "speaker-highlighted";
        } else if (this.speakerWordsMode) {
          return "speaker-hidden";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    selectPolitician: function(speaker) {
      if (this.selectedSpeaker !== null) {
        if (this.selectedSpeaker.politicianID === speaker.politicianID) {
          this.selectedSpeaker = null;
          this.$emit("selectedSpeaker", this.selectedSpeaker);
        } else {
          this.selectedSpeaker = speaker;
          if (!this.speakerWordsMode) {
            this.showPoliticianInfo = true;
          } else {
            this.$emit("selectedSpeaker", this.selectedSpeaker);
          }
        }
      } else {
        this.selectedSpeaker = speaker;
        if (!this.speakerWordsMode) {
          this.showPoliticianInfo = true;
        } else {
          this.$emit("selectedSpeaker", this.selectedSpeaker);
        }
      }
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    },
    showInfoCard: function(index) {
      this.visibleCard = index;
    },
    hideInfoCard: function() {
      this.visibleCard = null;
    },
    infoCardClass: function(index) {
      if (this.visibleCard === index) {
        return "show-card";
      } else {
        return "";
      }
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
      this.selectedPage = 1;
    },
    setSelectedPage: function(event) {
      this.selectedPage = event;
    },
    getStaticPoliticianImg: function(speaker) {
      let url =
        globalConfig.staticAssetsCDN +
        "/images/politicianID_" +
        speaker.politicianID +
        "_size_240.jpg";
      return url;
    }
  },
  computed: {
    periods: function() {
      return Object.keys(this.sectionData.govPeriods);
    },
    speakers: function() {
      if (
        Object.keys(this.sectionData.govPeriods).indexOf(
          this.selectedPeriod
        ) !== -1
      ) {
        let newSpeakers = [];
        for (let i = 0; i < this.totalPages; i++) {
          let start = 0 + i * 9;
          let end = 9 + i * 9;
          newSpeakers.push(
            this.sectionData.govPeriods[this.selectedPeriod].speakers.slice(
              start,
              end
            )
          );
        }
        return newSpeakers;
      } else {
        return [];
      }
    },
    totalPages: function() {
      // console.log(this.periods.indexOf(this.selectedPeriod));
      if (this.periods.indexOf(this.selectedPeriod) !== -1) {
        return Math.ceil(
          this.sectionData.govPeriods[this.selectedPeriod].speakers.length / 9
        );
      } else {
        return 1;
      }
    },
    emptyTiles: function() {
      if (this.speakers.length > 0) {
        return 9 - this.speakers[this.selectedPage - 1].length;
      } else {
        return 9;
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
